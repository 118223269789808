import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Sig1 from './commons/Benjamin_Franklin_Signature.svg';
import Sig2 from './commons/Thomas_Jefferson_Signature.svg';
import Watermark from './commons/specimen-watermark.png';
import NextIDLogo from './commons/logo-nextid.svg';

const Template = ({ document }) => {
  const scaleValue = (window.innerWidth * 0.8) / 1056;
  const translateX = 72;
  const translateY = 0;
  return (
    <div
      id="test"
      style={{
        textAlign: 'center',
        height: 768,
        width: 1056,
        position: 'relative',
        boxShadow: '0 2px 8px rgba(31,45,61,.05)',
        transform: `scale(${scaleValue}) translate(${translateX}px, ${translateY}px) `,
        transformOrigin: '0 0 0',
        marginBottom: '10%',
        paddingTop: 96,
      }}
    >
      <div
        style={{
          color: 'black',
          width: 992,
          height: 56,
          fontSize: 48,
          fontWeight: 500,
          textAlign: 'center',
          margin: '0px 32px 0px 32px',
        }}
      >
        NextID Pte Ltd
      </div>
      <div
        style={{
          color: 'black',
          width: '100%',
          height: 24,
          fontSize: 16,
          fontWeight: 400,
          letterSpacing: 0.44,
          textAlign: 'center',
          marginTop: 16,
          marginBottom: 24,
        }}
      >
        hereby certifies that
      </div>
      <div
        style={{
          color: 'black',
          width: '100%',
          height: 40,
          fontSize: 34,
          fontWeight: 500,
          textAlign: 'center',
          marginBottom: 8,
        }}
      >
        {get(document, 'recipient.name', 'Narongsak Mueangpaknoi')}
      </div>
      <div
        style={{
          color: 'black',
          width: '100%',
          height: 24,
          fontSize: 16,
          fontWeight: 400,
          letterSpacing: 0.44,
          textAlign: 'center',
          marginBottom: 8,
        }}
      >
        of
      </div>
      <div
        style={{
          color: 'black',
          width: '100%',
          height: 32,
          fontSize: 24,
          fontWeight: 500,
          letterSpacing: 0.44,
          textAlign: 'center',
          marginBottom: 24,
        }}
      >
        {get(document, 'description', 'Thailand')}
      </div>
      <div
        style={{
          color: 'black',
          width: '100%',
          height: 24,
          fontSize: 16,
          fontWeight: 400,
          letterSpacing: 0.44,
          textAlign: 'center',
          marginBottom: 24,
        }}
      >
        was awarded the
      </div>
      <div
        style={{
          color: 'black',
          width: '100%',
          height: 40,
          fontSize: 34,
          fontWeight: 500,
          letterSpacing: 0.25,
          textAlign: 'center',
          marginBottom: 24,
        }}
      >
        {get(document, 'name', 'Physics Olympiad')}
      </div>
      <div
        style={{
          color: 'black',
          width: '100%',
          height: 24,
          fontSize: 16,
          fontWeight: 400,
          letterSpacing: 0.44,
          textAlign: 'center',
          marginBottom: 32,
        }}
      >
        on {get(document, 'additionalData.awardDate', 'on January 4, 2020')} at{' '}
        {get(document, 'additionalData.awardPlace', 'University of Oxford')}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          paddingLeft: 73,
          paddingRight: 73,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <img style={{ marginBottom: 26.76 }} src={Sig1} alt=""></img>
          <div>Benjamin Franklin</div>
          <div>Founding Father</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <img
            style={{ marginTop: 53, marginBottom: 67 }}
            src={NextIDLogo}
            alt=""
          ></img>
          <div>Issued using NextID</div>
          <div>https://nextid.com</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            marginTop: 15,
          }}
        >
          <img style={{ marginBottom: 44.22 }} src={Sig2} alt=""></img>
          <div>Thomas Jefferson</div>
          <div>Founding Father</div>
        </div>
      </div>
      <img
        style={{
          position: 'fixed',
          height: 300,
          width: 790,
          bottom: 240,
          left: 130,
          zIndex: -99,
        }}
        src={Watermark}
        alt=""
      />
    </div>
  );
};

Template.propTypes = {
  document: PropTypes.object.isRequired,
};
export default Template;
