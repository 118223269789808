import Degree from './degree';

const templates = [
  {
    id: 'degree',
    label: 'Certificate',
    template: Degree,
  },
];

export default templates;
