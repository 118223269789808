import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
// import { Logo } from "./resources";
import { QRCode } from 'react-qr-svg';
import BG from './commons/bg/wca-cert-bg.svg';
import LOGO from './commons/logo/logo.svg';
import Signature from './commons/signatures/signature.png';

// const document = { description: 'The 1st International Robotics & Automation Summit and Competition – Robolution 2019',
// name: 'Winner',
// recipient:
//  { name: 'Sunny University',
//    affiliation: 'Sunny University',
//    email: 'wai1chung@gmail.com',
//    id: '2018-09-11-1234567' },
// additionalData:
//  { activity: 'UBTech Robots Competition – Sumobot Competition (Open Platform Robot)',
//    certificationUrl: 'https://google.com',
//    signatory1:
//     { designation: 'Mr Important #1',
//       name: 'Chairman' },
//    signatory2:
//     { designation: 'Mr Important #2',
//       name: 'CEO' } } };

const Template = ({ document }) => {
  const scaleValue = (window.innerWidth * 0.8) / 1056;
  const translateX = 72;
  const translateY = 0;
  return (
    <div
      id="test"
      style={{
        textAlign: 'center',
        height: 1493,
        width: 1056,
        position: 'relative',
        boxShadow: '0 2px 8px rgba(31,45,61,.05)',
        transform: `scale(${scaleValue}) translate(${translateX}px, ${translateY}px) `,
        transformOrigin: '0 0 0',
        marginBottom: '10%',
      }}
    >
      <div
        style={{
          height: 24,
          width: 'fit-content',
          marginTop: 16,
          marginLeft: 160,
          letterSpacing: 0.44,
          lineSpacing: 19,
          fontSize: 16,
        }}
      >
        {get(document, 'recipient.id', 'SERIAL-2018-08-01-112')}
      </div>
      <div
        style={{
          marginTop: 96.56,
          marginLeft: 754,
          width: 'fit-content',
        }}
      >
        {/* <Logo /> */}
        <img src={LOGO} alt=""></img>
      </div>
      <div
        style={{
          fontSize: 20,
          fontWeight: 500,
          letterSpacing: 0.15,
          lineSpacing: 24,
          textAlign: 'right',
          width: 256,
          height: 96,
          marginLeft: 704,
          marginTop: 24,
        }}
      >
        {get(document, 'description', 'The award description')}
      </div>
      <div
        style={{
          fontSize: 34,
          letterSpacing: 0.25,
          lineSpacing: 41,
          textAlign: 'left',
          width: 498,
          fontWeight: 500,
          height: 'fit-content',
          marginLeft: 160,
          marginTop: 116,
        }}
      >
        {get(document, 'additionalData.award', 'Best something of something')}
      </div>
      <div
        style={{
          height: 32,
          width: 'auto',
          marginLeft: 160,
          fontWeight: 400,
          marginTop: 16,
          fontSize: 24,
          lineSpacing: 29,
          textAlign: 'left',
        }}
      >
        {get(document, 'name', 'Name of the award')}
      </div>
      <div
        style={{
          marginTop: 48,
          textAlign: 'left',
          letterSpacing: 0.15,
          fontSize: 20,
          lineSpacing: 24,
          fontWeight: 400,
          width: 'fit-content',
          marginLeft: 160,
          height: 24,
        }}
      >
        {get(document, 'recipient.name', 'Awardee')}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 104,
          marginLeft: 160,
        }}
      >
        <div
          style={{
            marginTop: 32,
          }}
        >
          <img
            style={{ height: 111.5, width: 'auto' }}
            src={Signature}
            alt=""
          ></img>
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(document, 'additionalData.signatory.name', 'Mr Important')}
          </div>
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(
              document,
              'additionalData.signatory.designation',
              'Mr Important',
            )}
          </div>
        </div>
      </div>
      <QRCode
        bgColor="#FFFFFF"
        fgColor="#000000"
        level="H"
        style={{ width: 96, marginLeft: 754, marginTop: -100 }}
        value={get(
          document,
          'additionalData.certificationUrl',
          'https://nextid.com',
        )}
      />
      <img
        style={{
          width: '100%',
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: -99,
        }}
        src={BG}
        alt=""
      />
      <div
        style={{
          width: 956,
          height: 24,
          textAlign: 'right',
          marginTop: 62,
          marginLeft: 0,
          marginRight: 0,
          fontSize: 16,
          fontWeight: 400,
          letterSpacing: 0.44,
          lineSpacing: 19,
        }}
      >
        © {get(document, 'additionalData.issuerName', 'Erovoutika')}
      </div>
      <div
        style={{
          width: 956,
          textAlign: 'right',
          height: 24,
          marginLeft: 0,
          marginRight: 0,
          fontSize: 16,
          fontWeight: 400,
          letterSpacing: 0.44,
          lineSpacing: 19,
        }}
      >
        Issued on NextCert nextid.com
      </div>
    </div>
  );
};

Template.propTypes = {
  document: PropTypes.object.isRequired,
};
export default Template;
