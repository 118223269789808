import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { QRCode } from 'react-qr-svg';

import Heroes from './heroes.png';
import Badge from './badge.png';
import BG from './bg.jpg';

import { prettifyTime } from '../../../../lib/time';
import './certificate.css';

class Template extends React.Component {
  constructor(props) {
    super(props);
    this.nameRef = createRef();
  }

  render() {
    const { document } = this.props;
    const scaleValue = (window.innerWidth * 0.6) / 1056;
    const translateX = 72;
    const translateY = 0;
    const desc = get(document, 'description', '');
    let whiteLayer = 75;
    let footerMargin = 100;
    console.log(
      this.nameRef && this.nameRef.current && this.nameRef.current.clientHeight,
    );

    if (this.nameRef && this.nameRef.current) {
      const height = this.nameRef.current.clientHeight;
      if (height > 279) {
        whiteLayer = 82;
      } else if (height > 269) {
        whiteLayer = 81;
        footerMargin = 80;
      } else if (height > 215) {
        whiteLayer = 78;
      }
    }

    const Affiliation =
      desc == '' ? null : (
        <React.Fragment>
          &nbsp;at&nbsp;
          {desc}
        </React.Fragment>
      );
    return (
      <div
        id="test"
        className="template"
        style={{
          transform: `scale(${scaleValue}) translate(${translateX}px, ${translateY}px) `,
        }}
      >
        <div className="white-layer" style={{ height: `${whiteLayer}%` }}>
          {/* <div
            style={{
              width: "100%",
              color: "white",
              marginTop: 24,
              fontSize: 16,
              position: "absolute",
            }}
          >
            {get(document, "recipient.id", "SERIAL-2018-08-01-112")}
          </div> */}
          <div className="content">
            <img
              className="heroes-masthead"
              alt="heroes jumbotron"
              src={Heroes}
            />

            <span className="secondary-header mt-40 green  weight-600">
              VERIFIABLE DIGITAL
            </span>
            <div className="main-header">CERTIFICATE</div>
            <span className="secondary-header green weight-600">
              OF APPRECIATION
            </span>
            <span className="secondary-header mt-20">PROUDLY PRESENTED TO</span>
            <div className="recipient">
              <div className="name" ref={this.nameRef}>
                <span className="name-wrap">
                  {get(document, 'name', 'John Doe').trim(' ')}
                  {Affiliation}
                </span>
              </div>
              <div className="_border name-field"></div>
              <div className="message">
                for commendable and selfless work
                <br /> at the time when you were needed the most
              </div>
            </div>

            <div className="issuer">
              <div>
                <div className="data">
                  <span>{get(document, 'recipient.name', 'John Doe')}</span>
                </div>
                <div className="_border field" />
                <div className="title">Sender</div>
              </div>
              <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="H"
                style={{ width: 80, marginBottom: 10 }}
                value={get(document, 'additionalData.certificationUrl')}
              />
              <div>
                <div className="data">
                  <span>
                    {prettifyTime(
                      get(document, 'issuedOn', '2019-10-25T11:59:55.322Z'),
                    )}
                  </span>
                </div>
                <div className="_border field" />
                <div className="title">Date</div>
              </div>
            </div>
            <div className="orange">#CovidHeroes</div>
            <div className="badge">
              <img alt="certified covid hero" src={Badge} />
            </div>
            <div className="by" style={{ marginTop: footerMargin }}>
              Certificate produced using NextCert™ with support from <br />
              NextID Pte Ltd and Zilliqa Research Pte Ltd
            </div>
          </div>
        </div>
        <img
          alt=""
          src={BG}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            height: 1550,
            width: 1056,
            zIndex: -1,
          }}
        />
      </div>
    );
  }
}

Template.propTypes = {
  document: PropTypes.object.isRequired,
};
export default Template;
