import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { RoboLogo } from './resources';
import { QRCode } from 'react-qr-svg';
import BG from './commons/robo-bg/robolution-cert-bg.png';
import Colin from './commons/signatures/signature_image_colin_miles_800x500.png';
import Veron from './commons/signatures/signature_image_veronica_andrino_800x500.png';
import NextIDLogo from './commons/logos/nextid.svg';
import UnifinityLogo from './commons/logos/unifinity.png';

// const document = { description: 'The 1st International Robotics & Automation Summit and Competition – Robolution 2019',
// name: 'Winner',
// recipient:
//  { name: 'Sunny University',
//    affiliation: 'Sunny University',
//    email: 'wai1chung@gmail.com',
//    id: '2018-09-11-1234567' },
// additionalData:
//  { activity: 'UBTech Robots Competition – Sumobot Competition (Open Platform Robot)',
//    certificationUrl: 'https://google.com',
//    signatory1:
//     { designation: 'Mr Important #1',
//       name: 'Chairman' },
//    signatory2:
//     { designation: 'Mr Important #2',
//       name: 'CEO' } } };

const Template = ({ document }) => {
  const scaleValue = (window.innerWidth * 0.8) / 1056;
  const translateX = 72;
  const translateY = 0;
  return (
    <div
      id="test"
      style={{
        backgroundColor: 'white',
        textAlign: 'center',
        height: 1493,
        width: 1056,
        position: 'relative',
        boxShadow: '0 2px 8px rgba(31,45,61,.05)',
        transform: `scale(${scaleValue}) translate(${translateX}px, ${translateY}px) `,
        transformOrigin: '0 0 0',
        marginBottom: '10%',
      }}
    >
      <div
        style={{
          height: 24,
          width: 'fit-content',
          marginTop: 16,
          marginLeft: 160,
          letterSpacing: 0.44,
          lineSpacing: 19,
          fontSize: 16,
        }}
      >
        {get(document, 'recipient.id', '')}
      </div>
      <div
        style={{
          marginTop: 170,
          marginLeft: 567,
          width: 'fit-content',
        }}
      >
        <RoboLogo />
      </div>
      <div
        style={{
          fontSize: 20,
          fontWeight: 500,
          letterSpacing: 0.15,
          lineSpacing: 24,
          textAlign: 'right',
          width: 256,
          height: 96,
          marginLeft: 704,
          marginTop: 24,
        }}
      >
        {get(document, 'description', '')}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 142 }}>
        <div>
          <div
            style={{
              fontSize: 34,
              letterSpacing: 0.25,
              lineSpacing: 41,
              textAlign: 'left',
              width: 498,
              fontWeight: 500,
              height: 'fit-content',
              marginLeft: 160,
            }}
          >
            {get(document, 'additionalData.activity', '')}
          </div>
          <div
            style={{
              height: 'auto',
              width: 400,
              marginLeft: 160,
              fontWeight: 400,
              marginTop: 16,
              fontSize: 24,
              lineSpacing: 29,
              textAlign: 'left',
            }}
          >
            {get(document, 'recipient.affiliation', '')}
            <p>{get(document, 'name', '')}</p>
          </div>
          <div
            style={{
              marginTop: 48,
              textAlign: 'left',
              letterSpacing: 0.15,
              fontSize: 20,
              lineSpacing: 24,
              fontWeight: 400,
              width: 'fit-content',
              marginLeft: 160,
              height: 24,
            }}
          >
            {get(document, 'recipient.name', '')}
          </div>
        </div>
        <QRCode
          bgColor="#FFFFFF"
          fgColor="#000000"
          level="H"
          style={{ width: 96, marginLeft: 206 }}
          value={get(
            document,
            'additionalData.certificationUrl',
            'https://nextid.com',
          )}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 104,
          marginLeft: 160,
        }}
      >
        <div
          style={{
            marginTop: 32,
          }}
        >
          <img style={{ height: 176, width: 'auto' }} src={Colin} alt=""></img>
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(document, 'additionalData.signatory1.name', '')}
          </div>
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(document, 'additionalData.signatory1.designation', '')}
          </div>
        </div>
        <div
          style={{
            marginTop: 32,
          }}
        >
          <img style={{ height: 176, width: 'auto' }} src={Veron} alt=""></img>
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(document, 'additionalData.signatory2.name', '')}
          </div>
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(document, 'additionalData.signatory2.designation', '')}
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: 160,
          marginTop: 106,
        }}
      >
        <img
          alt="img"
          style={{ marginRight: 32, height: 115, width: 100 }}
          src={UnifinityLogo}
        ></img>
        <img alt="img" src={NextIDLogo}></img>
      </div>
      <div
        style={{
          width: 1006,
          textAlign: 'left',
          height: 24,
          marginLeft: 160,
          marginRight: 0,
          marginTop: 20,
          fontSize: 16,
          fontWeight: 400,
          letterSpacing: 0.44,
          lineSpacing: 19,
        }}
      >
        Issued by Unifinity.io on NextCert
      </div>
      <img
        style={{
          width: '100%',
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: -99,
        }}
        src={BG}
        alt=""
      />
      <div
        style={{
          width: 1006,
          height: 24,
          marginTop: 0,
          marginLeft: 0,
          marginRight: 0,
          fontSize: 16,
          fontWeight: 400,
          letterSpacing: 0.44,
          lineSpacing: 19,
          textAlign: 'right',
        }}
      >
        © Erovoutika
      </div>
    </div>
  );
};

Template.propTypes = {
  document: PropTypes.object.isRequired,
};
export default Template;
