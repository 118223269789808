// This file is the template registry serves as the template registry.
// When templates are loaded, their name will be compared to the keys of the
// exported object and that template will be used. If a template cannot be
// found, the default template will be used instead.

// TODO: Use dynamic loading to prevent all templates to be loaded at once.
// import DefaultTemplate from "./default";
import CustomTemplate from './customTemplate';
import E27Template from './E27-2019-ECHELON-CERT';
import Robo2019Template from './ROBO-2019-CERT';
import DefaultDiploma from './DEFAULT-DIPLOMA';
import DefaultTranscript from './DEFAULT-TRANSCRIPT';
import WCATemplate from './WCA-2019-CERT';
import CareHabTemplate from './nextid/nextid/CAREHAB-2020';
import AdhocTemplate from './nextid/sitepromo/ADHOC-CERT';
import ForwardSchoolTemplate from './nextid/forwardschool/FORWARD-SCHOOL-2020';
import CovidHeroesTemplate from './nextid/covidheroes';
import nusTemplates from './NUS';

export default {
  default: Robo2019Template,
  CUSTOM_TEMPLATE: CustomTemplate,
  E27_2019_TEMPLATE: E27Template,
  DEFAULT_DIPLOMA: DefaultDiploma,
  DEFAULT_TRANSCRIPT: DefaultTranscript,
  ROBO_2019_TEMPLATE: Robo2019Template,
  WCA_2019_TEMPLATE: WCATemplate,
  CAREHAB_2020_TEMPLATE: CareHabTemplate,
  FORWARD_2020_TEMPLATE: ForwardSchoolTemplate,
  COVIDHEROES_2020_TEMPLATE: CovidHeroesTemplate,
  ADHOC_CERT: AdhocTemplate,
  ...nusTemplates,
  NULL: [],
};
