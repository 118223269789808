import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { documentTemplates } from './utils';
import ExternalTemplate from '../ExternalTemplate';
// import "./documentViewer.css";

const NEXTCERT_LAYOUT = 'nextcert-layout';

export const getType = (document) => {
  if (document.additionalData) {
    return get(document, 'additionalData.template.type', '').toLowerCase();
  } else if (document.template) {
    return get(document, 'template.layout.type', '').toLowerCase();
  } else {
    return '';
  }
};

export const getUrl = (document) => {
  if (document.additionalData) {
    return get(document, 'additionalData.layout.url', '');
  } else if (document.template) {
    return get(document, 'template.layout.url', '');
  } else {
    return '';
  }
};

const DocumentViewer = (props) => {
  const { tabIndex, document, handleHeightUpdate, handleObfuscation } = props;
  const type = getType(document);
  console.log(`Renderer Type: ${type}`);
  // Feat/nextcert-layout
  // Added a new handler to if template should render externally or internally
  // If template url is not defined or any errors occur, it will fall back to the
  // embedded renderer.
  try {
    if (type === NEXTCERT_LAYOUT) {
      const url = getUrl(document);
      if (!url) {
        // We throw a string to omit stack trace
        throw 'Attempted to load external cert. Empty url';
      }
      return (
        <ExternalTemplate
          document={document}
          handleObfuscation={handleObfuscation}
        />
      );
    }
    // We throw a string to omit stack trace
    throw 'Using embedded certs';
  } catch (e) {
    console.log(e);
  }

  // Feat/nextcert-layout
  // Proceeds to load template via the embedded renderer.
  const templates = documentTemplates(document, handleHeightUpdate);
  const Template = templates[tabIndex].template;

  return <Template document={document} handleObfuscation={handleObfuscation} />;
};

DocumentViewer.propTypes = {
  document: PropTypes.object.isRequired,
  handleHeightUpdate: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  handleObfuscation: PropTypes.func,
};

export default DocumentViewer;
