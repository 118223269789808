import Certificate from './certificate';

const templates = [
  {
    id: 'E27',
    label: 'Document',
    template: Certificate,
  },
];

export default templates;
