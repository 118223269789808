import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { QRCode } from 'react-qr-svg';

//images
import LOGO from './commons/carehab-logo.png';
import BG from './commons/background.jpg';
import Signature2 from './commons/signature2.png';
import Signature1 from './commons/signature1.png';
import Org from './commons/org1.png';
import Coorg1 from './commons/coorg1.png';
import Coorg2 from './commons/coorg2.png';
import OfficialCertPartnerLogo from './commons/logo-nextid.png';

const Template = ({ document }) => {
  const scaleValue = (window.innerWidth * 0.8) / 1056;
  const translateX = 72;
  const translateY = 0;
  return (
    <div
      id="test"
      style={{
        textAlign: 'center',
        height: 1493,
        width: 1056,
        position: 'relative',
        boxShadow: '0 2px 8px rgba(31,45,61,.05)',
        transform: `scale(${scaleValue}) translate(${translateX}px, ${translateY}px) `,
        transformOrigin: '0 0 0',
        marginBottom: '10%',
      }}
    >
      <div
        style={{
          height: 24,
          width: 'fit-content',
          marginTop: 16,
          marginLeft: 160,
          letterSpacing: 0.44,
          lineSpacing: 19,
          fontSize: 16,
        }}
      >
        {get(document, 'recipient.id', 'SERIAL-2018-08-01-112')}
      </div>
      <div
        style={{
          marginTop: 96.56,
          marginLeft: 716,
          width: 'fit-content',
        }}
      >
        {/* <Logo /> */}
        <img style={{ height: 'auto', width: 244 }} src={LOGO} alt=""></img>
      </div>
      <div
        style={{
          fontSize: 20,
          fontWeight: 500,
          letterSpacing: 0.15,
          lineSpacing: 24,
          textAlign: 'right',
          width: 256,
          height: 96,
          marginLeft: 703,
          marginTop: 24,
        }}
      >
        {get(document, 'description', 'The award description')}
      </div>
      <div
        style={{
          fontSize: 34,
          letterSpacing: 0.25,
          lineSpacing: 41,
          textAlign: 'left',
          width: 498,
          fontWeight: 500,
          height: 'fit-content',
          marginLeft: 160,
          marginTop: 116,
        }}
      >
        {get(document, 'additionalData.award', 'Best something of something')}
      </div>
      <div
        style={{
          height: 32,
          width: 'auto',
          marginLeft: 160,
          fontWeight: 400,
          marginTop: 16,
          fontSize: 24,
          lineSpacing: 29,
          textAlign: 'left',
        }}
      >
        {get(document, 'name', 'Name of the award')}
      </div>
      <div
        style={{
          marginTop: 48,
          textAlign: 'left',
          letterSpacing: 0.15,
          fontSize: 20,
          lineSpacing: 24,
          fontWeight: 400,
          width: 'fit-content',
          marginLeft: 160,
          height: 24,
        }}
      >
        {get(document, 'recipient.name', 'Awardee')}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 130,
          marginLeft: 160,
        }}
      >
        <div
          style={{
            marginTop: 32,
            marginRight: 20,
            width: 320,
          }}
        >
          <img
            style={{
              height: 119.5,
              width: 'auto',
              marginRight: 110,
              marginBottom: 36,
            }}
            src={Signature1}
            alt=""
          ></img>
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(
              document,
              'additionalData.signatory1.name',
              'Dr Geoffrey Sithamparapillai Samuel',
            )}
          </div>
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(
              document,
              'additionalData.signatory1.designation',
              "MBBS (S'pore), MRCP (UK), FAMS, MCI",
            )}
          </div>
          <div
            style={{
              textAlign: 'left',
              marginTop: 8,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 12,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(
              document,
              'additionalData.signatory1.description1',
              'Organising Chairperson, the 6th Singapore Rehabilitation Conference',
            )}
          </div>
          <div
            style={{
              textAlign: 'left',
              marginTop: 0,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 12,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(
              document,
              'additionalData.signatory1.description2',
              'Society of Rehabilitation Medicine (Singapore)',
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: 32,
          }}
        >
          <img
            style={{ height: 121.63, width: 'auto', marginBottom: 36 }}
            src={Signature2}
            alt=""
          ></img>
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(document, 'additionalData.signatory2.name', 'Lee Jeong Hoon')}
          </div>
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(
              document,
              'additionalData.signatory2.designation',
              'Cluster Director',
            )}
          </div>
          <div
            style={{
              textAlign: 'left',
              marginTop: 8,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 12,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(
              document,
              'additionalData.signatory2.description1',
              'CAREhab 2020',
            )}
          </div>
          <div
            style={{
              textAlign: 'left',
              marginLeft: 0,
              marginRight: 0,
              fontSize: 12,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(
              document,
              'additionalData.signatory2.description2',
              'Healthcare & Sustainability Cluster',
            )}
          </div>
          <div
            style={{
              textAlign: 'left',
              marginLeft: 0,
              marginRight: 0,
              fontSize: 12,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            {get(
              document,
              'additionalData.signatory2.description3',
              'Singex Exhibitions Pte Ltd',
            )}
          </div>
        </div>
      </div>
      <QRCode
        bgColor="#FFFFFF"
        fgColor="#000000"
        level="H"
        style={{ width: 96, marginLeft: 754, marginTop: -1080 }}
        value={get(
          document,
          'additionalData.certificationUrl',
          'https://nextid.com',
        )}
      />
      <img
        style={{
          width: '100%',
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: -99,
        }}
        src={BG}
        alt=""
      />
      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 160 }}>
        <div style={{ marginRight: 31.5 }}>
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
              marginBottom: 36,
              marginTop: 90,
            }}
          >
            Organised by
          </div>
          <img
            style={{ height: 56, width: 'auto', marginTop: 15 }}
            src={Org}
            alt=""
          />
          <div
            style={{
              height: 24,
              textAlign: 'left',
              marginTop: 44,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            © {get(document, 'additionalData.issuerName', 'Singex Exhibitions')}
          </div>
        </div>
        <div>
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
              marginBottom: 36,
              marginTop: 90,
            }}
          >
            Co-organised by
          </div>
          <img
            style={{ height: 90, width: 'auto', marginRight: 32 }}
            src={Coorg1}
            alt=""
          />
          <img style={{ height: 90, width: 'auto' }} src={Coorg2} alt="" />
        </div>
        <div style={{ marginLeft: 170, marginTop: 30 }}>
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
              marginBottom: 36,
              marginTop: 90,
            }}
          ></div>
          <img src={OfficialCertPartnerLogo} alt="" />
          <div
            style={{
              textAlign: 'left',
              height: 24,
              marginTop: 50,
              marginLeft: 0,
              marginRight: 21,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.44,
              lineSpacing: 19,
            }}
          >
            Issued by on NextCert
          </div>
        </div>
      </div>
    </div>
  );
};

Template.propTypes = {
  document: PropTypes.object.isRequired,
};
export default Template;
