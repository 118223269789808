import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { QRCode } from 'react-qr-svg';

import FS_LOGO from './forward-school-logo.png';
import BG from './cert_bg.svg';
import SIG from './sig.svg';

import { prettifyTime } from '../../../../../lib/time';

const Template = ({ document }) => {
  const scaleValue = (window.innerWidth * 0.8) / 1056;
  const translateX = 72;
  const translateY = 0;
  return (
    <div
      id="test"
      style={{
        backgroundColor: 'white',
        textAlign: 'center',
        height: 1650,
        width: 1056,
        position: 'relative',
        boxShadow: '0 2px 8px rgba(31,45,61,.05)',
        transform: `scale(${scaleValue}) translate(${translateX}px, ${translateY}px) `,
        transformOrigin: '0 0 0',
        marginBottom: '10%',
      }}
    >
      <div
        style={{
          width: '100%',
          color: 'white',
          marginTop: 24,
          fontSize: 16,
          position: 'absolute',
        }}
      >
        {get(document, 'recipient.id', 'SERIAL-2018-08-01-112')}
      </div>

      <img
        alt=""
        src={FS_LOGO}
        style={{ width: 250, height: 'auto', marginTop: 230 }}
      />

      <div
        style={{
          color: '#2152A5',
          marginTop: 40,
          fontSize: 160,
          fontWeight: 700,
          transform: 'scaleY(0.9)',
        }}
      >
        Certificate
      </div>
      <div
        style={{
          color: '#2152A5',
          transform: 'scaleY(0.9)',
          fontWeight: 700,
          marginTop: -80,
          fontSize: 58,
        }}
      >
        of Completion
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 30,
        }}
      >
        <div style={{ color: '#2152A5', marginTop: 30, fontSize: 25 }}>
          This certificate is awarded to
        </div>
        <div
          style={{
            color: '#000000',
            marginTop: 0,
            fontSize: 40,
            width: 800,
            height: 120,
            marginBottom: 10,
            position: 'relative',
            overflowY: 'hidden',
          }}
        >
          <span
            style={{
              display: 'block',
              position: 'absolute',
              bottom: 0,
              width: '100%',
            }}
          >
            {get(document, 'recipient.name', 'John Doe')}
          </span>
        </div>
        <div
          style={{ border: '1px solid #718397', height: 0, width: 690 }}
        ></div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 25,
        }}
      >
        <div style={{ color: '#2152A5', marginTop: 30, fontSize: 25 }}>
          for completing
        </div>
        <div
          style={{
            color: '#000000',
            marginTop: 0,
            fontSize: 33,
            marginBottom: 10,
            height: 100,
            width: 700,
            position: 'relative',
            overflowY: 'hidden',
          }}
        >
          <span
            style={{
              display: 'block',
              position: 'absolute',
              bottom: 0,
              width: '100%',
            }}
          >
            {get(
              document,
              'name',
              'This title has been deliberately made unnecessarily long for testing purposes and is thus necessarily long',
            )}
          </span>
        </div>
        <div
          style={{ border: '1px solid #718397', height: 0, width: 690 }}
        ></div>
      </div>

      <div
        style={{
          color: '#2152A5',
          display: 'block',
          margin: '30px auto 0 auto',
          fontSize: 25,
          width: 600,
          lineHeight: '29px',
        }}
      >
        Forward School has confirmed the participation of this individual in
        this online webinar under the Virtual Classroom Initiative
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          left: 194,
          bottom: 240,
          position: 'absolute',
        }}
      >
        <QRCode
          bgColor="#FFFFFF"
          fgColor="#000000"
          level="H"
          style={{ width: 90, marginBottom: 10 }}
          value={get(document, 'additionalData.certificationUrl')}
        />
        <div style={{ color: '#2152A5', fontSize: 15, lineHeight: '15px' }}>
          {prettifyTime(get(document, 'issuedOn', '2019-10-25T11:59:55.322Z'))}
        </div>
        <div style={{ color: '#2152A5', fontSize: 15 }}>Issued on NextCert</div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          right: 194,
          bottom: 240,
          position: 'absolute',
        }}
      >
        <img
          alt=""
          src={SIG}
          style={{
            width: 120,
            height: 'auto',
            transform: 'translate(-8px, 50px)',
          }}
        />
        <div style={{ color: '#2152A5', fontSize: 15, lineHeight: '15px' }}>
          Howie Change
        </div>
        <div style={{ color: '#2152A5', fontSize: 15 }}>Founder and CEO</div>
      </div>

      <img
        alt=""
        src={BG}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          height: 1650,
          width: 1056,
          zIndex: -1,
        }}
      />
    </div>
  );
};

Template.propTypes = {
  document: PropTypes.object.isRequired,
};
export default Template;
