import Certificate from './certificate';

const templates = [
  {
    id: 'COVIDHEROES_2020',
    label: 'Document',
    template: Certificate,
  },
];

export default templates;
